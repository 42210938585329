import React from 'react';

import Confetti from './assets/confetti.svg';
import Ellipsis from './assets/ellipsis.svg';
import Wave from './assets/wave.svg';

var backgroundStyle = {
  backgroundImage: `url(${Confetti}), url(${Wave}), url(${Ellipsis})`,
  backgroundPosition: 'top left, top right, top left',
  backgroundSize: 'cover, cover, cover',
};

function App() {
  return (
    <div className='background-images' style={ backgroundStyle }>
      <div className='container box-container'>
        <div className='row'>
          <div className='col-12 col-md-6 top-column'>
            <img className='img-fluid' alt='Logo' src={require('./assets/logo.png')} />
            <h1 className='title-text'>
              Somos&nbsp;
              <br className='break-line' />
              <div id='typed-strings'>
                <span>designers</span>
                <span>devs</span>
                <span>amigos</span>
                <span>criativos</span>
                <span>tecnologia</span>
                <span>EngApp</span>
              </div>
              <span className='typed-className' id='typed'></span>
            </h1>
            <p className='subtitle-text'>Lamentamos, mas o nosso site está em construção.<br/>Estamos trabalhando para oferecer a você uma ótima experiência!</p>
            <ul className='list-inline'>
              <li className='list-inline-item social-margin'><a className='link-icon' href='https://www.facebook.com/engapplabs'><i className='fab fa-facebook-f social-icon'></i></a></li>
              <li className='list-inline-item social-margin'><a className='link-icon' href='https://www.instagram.com/engapplabs'><i className='fab fa-instagram social-icon'></i></a></li>
              <li className='list-inline-item social-margin'><a className='link-icon' href='https://www.twitter.com/engapplabs'><i className='fab fa-twitter social-icon'></i></a></li>
              <li className='list-inline-item social-margin'><a className='link-icon' href='https://www.linkedin.com/company/engapplabs'><i className='fab fa-linkedin-in social-icon'></i></a></li>
              <li className='list-inline-item social-margin'><a className='link-icon' href='https://www.github.com/engapplabs'><i className='fab fa-github social-icon'></i></a></li>
              <li className='list-inline-item social-margin'><a className='link-icon' href='https://www.medium.com/@engapplabs'><i className='fab fa-medium-m social-icon'></i></a></li>
            </ul>
          </div>
          <div className='col text-right'><img className='img-fluid hexagon-icon' alt='Hexagon' src={require('./assets/hexagon.svg')} /></div>
        </div>
      </div>
      <nav className='navbar navbar-dark navbar-expand fixed-bottom bg-dark bottombar'>
        <div className='container-fluid'>
          <div className='row row-bottombar'>
            <div className='col-sm-12 col-md-6'>
              <p className='bottom-text'>© Todos os direitos reservados</p>
            </div>
            <div className='col-sm-12 col-md-6 text-right'>
              <p className='bottom-text'>Feito com <span><i className='fas fa-heart heart-icon'></i></span> por EngApp</p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default App;
